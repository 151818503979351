import { ChangeDetectorRef, Directive, ViewContainerRef } from "@angular/core";

/**
 * Directive to be used on host HTML elements (e.g. a <div>) in which an Angular component
 * should be injected dynamically.
 * More info about this here: https://angular.io/guide/dynamic-component-loader
 */
@Directive({
  selector: "[componentContainer]"
})
export class ComponentContainerDirective {
  constructor(
    public viewContainerRef: ViewContainerRef,
    public cd: ChangeDetectorRef
  ) {}
}
