import { ChangeDetectionStrategy, Component, ElementRef, HostListener, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { GlobalStateAndEventsServiceEn } from "@core-sub/en-global-state-and-events.service";
import { EnrollmentAuthenticationService } from "@core-sub/security/enrollment-authentication.service";
import { EnrolleeInfoTrackingService } from "@core-sub/utility-services/enrollee-info-tracking.service";
import { UntilDestroy } from "@ngneat/until-destroy";
import { FadeAnimations } from "@shared-sub/animations/fade-animations";
import { RouteNamesEn } from "@shared-sub/classes/RouteNamesEn";
import { MenuItem } from "primeng/api";
import { EnrollmentStep, isEnrollmentStep } from "./EnrollmentStep";

@UntilDestroy()
@Component({
  selector: "en-enrollment-steps-main",
  templateUrl: "./enrollment-steps-main.component.html",
  styleUrls: ["./enrollment-steps-main.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [FadeAnimations.fadeInOut],
  standalone: false
})
export class EnrollmentStepsMainComponent implements OnInit, OnDestroy {
  public currentStep = 1;

  public stepItems: Array<MenuItem>;

  /**
   * Only relevant for desktop. Should be true when user hits the close button
   */
  public showOnCloseWarning = false;

  private internalScrollingArea: ElementRef<HTMLDivElement>;

  public set scrollingArea(value: ElementRef<HTMLDivElement>) {
    this.internalScrollingArea = value;
    if (this.currentStepComponent) {
      this.currentStepComponent.setScrollingArea(value);
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize() {
    this.showHideMenuAndOverlay(window.innerWidth);
  }

  private currentStepComponent: EnrollmentStep;

  constructor(
    private router: Router,
    private globalStateService: GlobalStateAndEventsServiceEn,
    private authService: EnrollmentAuthenticationService,
    private enrolleeTracker: EnrolleeInfoTrackingService
  ) {}

  public ngOnInit() {
    if (!this.enrolleeTracker.getTrackingInfo()) this.enrolleeTracker.setTrackingInfo(window.location.href, document.referrer);

    this.globalStateService.showHideTopMenuAndOverlay();

    // use dummy labels for the steps module and then hide via css. They need to be there for correct layout!
    this.stepItems = [
      { routerLink: RouteNamesEn.enrollmentStepsRoutes.step1, label: "w" },
      { routerLink: RouteNamesEn.enrollmentStepsRoutes.step2, label: "w" },
      { routerLink: RouteNamesEn.enrollmentStepsRoutes.step3, label: "w" }
    ];
  }

  public ngOnDestroy() {
    this.globalStateService.setState({
      ...this.globalStateService.getCurrentStateValue(),
      showTopMenubar: true,
      showOverlay: false
    });
  }

  public goBack() {
    const backOverridden = this.currentStepComponent?.onBackClicked() ?? false;
    if (backOverridden) return;

    if (this.currentStep === 1) this.router.navigate(["/"]);
    else if (this.currentStep === 2) this.router.navigate([RouteNamesEn.enrollmentStepsRoutes.main, RouteNamesEn.enrollmentStepsRoutes.step1]);
    else if (this.currentStep === 3) this.router.navigate([RouteNamesEn.enrollmentStepsRoutes.main, RouteNamesEn.enrollmentStepsRoutes.step2]);
  }

  public onAbortClicked() {
    // Handle the case where the close button is used internally by the step, e.g. for the Terms & Conditions
    const closeOverridden = this.currentStepComponent?.onAbortClicked() ?? false;
    if (closeOverridden) return;

    let doClose = false;

    // Handle both if user hit the "X" button or the "Confirm abort button"
    if (this.showOnCloseWarning) {
      // Confirm button clicked
      doClose = true;
    } else {
      this.showOnCloseWarning = this.currentStep >= 2;
      doClose = !this.showOnCloseWarning;
    }
    if (doClose) {
      this.authService.clearData();
      return this.router.navigate(["/"]);
    }
  }

  public onActivate(activatedStep: EnrollmentStep) {
    this.determineCurrentStep();

    if (isEnrollmentStep(activatedStep)) {
      this.currentStepComponent = activatedStep;
      if (this.internalScrollingArea) {
        this.currentStepComponent.setScrollingArea(this.internalScrollingArea);
      }
    } else {
      this.currentStepComponent = null;
    }
  }

  private determineCurrentStep() {
    if (this.router.url.indexOf(RouteNamesEn.enrollmentStepsRoutes.step2) > -1) this.currentStep = 2;
    else if (this.router.url.indexOf(RouteNamesEn.enrollmentStepsRoutes.step3) > -1) this.currentStep = 3;
  }

  private showHideMenuAndOverlay(screenSize: number) {
    const globalState = this.globalStateService.getCurrentStateValue();
    // Depending on screen size, show/hide top menu bar and the overlay
    if (screenSize < 576 && (globalState.showTopMenubar || globalState.showOverlay))
      this.globalStateService.setState({
        ...this.globalStateService.getCurrentStateValue(),
        showTopMenubar: false,
        showOverlay: false
      });
    else if (screenSize >= 576 && (!globalState.showTopMenubar || !globalState.showOverlay)) {
      this.globalStateService.setState({
        ...this.globalStateService.getCurrentStateValue(),
        showTopMenubar: true,
        showOverlay: true
      });
    }
  }
}
