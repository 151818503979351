import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { BiLanguageId } from "@globals/enums/BiLanguageAndCountryId";

/**
 * Component base class for components used for dsisplaying a specific version of a text in a specific language
 */
@Component({
    selector: "__", template: "",
    standalone: false
})
export abstract class DisplayTextBaseComponent implements OnInit {
  /**
   * The language id for determining the localized text to use
   */
  public languageId: BiLanguageId;

  /**
   * The text to display
   */
  public text: SafeHtml;

  protected abstract danishText: string;
  protected abstract swedishText: string;
  protected abstract finnishText: string;
  protected abstract norwegianText: string;
  protected abstract englishText: string;

  constructor(protected sanitizer: DomSanitizer) {}

  public ngOnInit() {
    switch (this.languageId) {
      case BiLanguageId.DK:
        this.text = this.sanitizer.bypassSecurityTrustHtml(this.danishText);
        break;
      case BiLanguageId.SE:
        this.text = this.sanitizer.bypassSecurityTrustHtml(this.swedishText);
        break;
      case BiLanguageId.EN:
        this.text = this.sanitizer.bypassSecurityTrustHtml(this.englishText);
        break;
      case BiLanguageId.FI:
        this.text = this.sanitizer.bypassSecurityTrustHtml(this.finnishText);
        break;
      case BiLanguageId.NO:
        this.text = this.sanitizer.bypassSecurityTrustHtml(this.norwegianText);
        break;
    }
  }
}
