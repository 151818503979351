import { Injectable } from "@angular/core";
import { EnrolleeService } from "@core-sub/services/enrollee.service";
import { BiTranslateService } from "@globals/bi-translate";
import { landLineLength_FI, landLineLength_SE, phoneLength_DE, phoneLength_DK, phoneLength_FI, phoneLength_NO, phoneLength_SE } from "@globals/classes/global-constants";
import { BiCountryId, BiLanguageId } from "@globals/enums/BiLanguageAndCountryId";
import { PhoneCodeEnum } from "@globals/enums/PhoneCodeEnum";
import { isStringNullOrEmpty } from "@globals/helper-functions";
import moment, { Moment } from "moment-timezone";
import "moment/locale/da";
import "moment/locale/sv";
import { map } from "rxjs";

export interface LanguageConfig {
  dateFormat: string;
  dateTimeFormat: string;
  dateFormatNoYear: string;
  phoneCountryCode: number;
  minimumPhoneLength: number;
  maximumPhoneLength: number;

  /**
   * Min length of landline numbers
   */
  phoneLengthLandlineMin: number;
  /**
   * Max length of land line numbers
   */
  phoneLengthLandlineMax: number;

  /**
   * Max. zip code length. For instance, Some can be up to 5
   */
  zipcodeLength: number;
  defaultMapCenterLatitude: number;
  defaultMapCenterLongitude: number;
  countryCode: string;
}

/**
 * Helper service with methods for localization stuff. This includes formatting of date times based on customer/profile timezone,
 * phone number country prefix and phone number length requirement (depending on country and language).
 * NOTE: This class uses Moment Timezone in order to use specific time zone depending on user or customer.
 *
 */
@Injectable({ providedIn: "root" })
export class EnLocalizationHelperService {
  ////======  Public properties with language and country specific information to be used all over web app ============
  public dateFormat = "DD/MM/YYYY";
  public dateFormatNoYear = "DD/MM";
  public dateTimeFormat = "DD/MM/YYYY HH:mm";
  public dateTimeSecondsFormat = this.dateTimeFormat + ":ss";

  /**
   * Phone number prefix determined by the country of the customer
   */
  public phoneCountryCode = 45;

  /**
   * How long phone numbers should be. Default 8 (DK)
   */
  public minimumPhoneLength = 8;
  public maximumPhoneLength = 8;
  /**
   * How long zipcodes should be
   */
  public zipcodeLength = 4;

  /**
   * This is based on current Enrollee's language Setting.
   */
  public enrolleeLanguageId = 1;

  ////==========================================

  constructor(
    private enrolleeService: EnrolleeService,
    private translator: BiTranslateService
  ) {
    moment.tz.setDefault("Europe/Copenhagen"); // now everytime we create moment date, it's treated with this timezone

    this.initEnroleeLanguageUpdateSubscription();
  }

  private initEnroleeLanguageUpdateSubscription() {
    // Subscribe to changes in enrollee's language
    this.enrolleeService.state$.pipe(map(s => s.currentLanguageId)).subscribe(langId => {
      this.setLanguage(langId);
      switch (langId) {
        case BiLanguageId.DK:
          this.dateFormat = "DD/MM/YYYY";
          this.dateFormatNoYear = "DD/MM";
          this.dateTimeFormat = "DD/MM/YYYY HH:mm";
          this.enrolleeLanguageId = BiLanguageId.DK;
          moment.locale("da");
          break;

        case BiLanguageId.SE:
          this.translator.use("se");
          this.dateFormat = "YYYY-MM-DD";
          this.dateFormatNoYear = "MM-DD";
          this.dateTimeFormat = "YYYY-MM-DD HH:mm";
          this.enrolleeLanguageId = BiLanguageId.SE;
          moment.locale("sv");
          break;

        case BiLanguageId.EN:
          this.translator.use("en");
          this.dateFormat = "DD/MM/YYYY";
          this.dateFormatNoYear = "DD/MM";
          this.dateTimeFormat = "DD/MM/YYYY HH:mm";
          this.enrolleeLanguageId = BiLanguageId.EN;
          moment.locale("en");
          break;

        case BiLanguageId.FI:
          this.translator.use("fi");
          this.dateFormat = "DD/MM/YYYY";
          this.dateFormatNoYear = "DD/MM";
          this.dateTimeFormat = "DD/MM/YYYY HH:mm";
          this.enrolleeLanguageId = BiLanguageId.FI;
          moment.locale("fi");
          break;

        case BiLanguageId.NO:
          this.translator.use("no");
          this.dateFormat = "DD/MM/YYYY";
          this.dateFormatNoYear = "DD/MM";
          this.dateTimeFormat = "DD/MM/YYYY HH:mm";
          this.enrolleeLanguageId = BiLanguageId.NO;
          moment.locale("nn");
          break;
      }
    });
  }

  public setLanguage(languageId?: BiLanguageId) {
    switch (languageId) {
      case BiLanguageId.DK:
        this.translator.use("da");
        break;
      case BiLanguageId.SE:
        this.translator.use("se");
        break;
      case BiLanguageId.EN:
        this.translator.use("en");
        break;
      case BiLanguageId.FI:
        this.translator.use("fi");
        break;
      case BiLanguageId.NO:
        this.translator.use("no");
        break;
    }
  }

  //#region DATE TIME LOCALIZATION (note: all moment objects will be treated with correct time zone as we use the moment.tz.setDefault above)
  /**
   * Translates a native JS Date or Moment object to a formatted string based on language
   */
  public localizeDateTime(theDate: string | Moment, showSeconds = false): string {
    const format = this.dateTimeFormat + (showSeconds ? ":ss" : "");
    if (theDate) {
      // check if already a moment object
      if (moment.isMoment(theDate)) return theDate.format(format); // we call "toUpper()" as the format is in lowercase (for the datepicker) but Moment Js expects upper case

      return moment(theDate).format(format);
    }
    return "";
  }

  /**
   * Takes UTC string or Moment UTC instance and formats it for display.
   * @param dateTimeStringUtc Date string in UTC format OR a Moment Js UTC instance
   * @param toDefaultDate Set true if you ONLY want a date output and use the default value (depending on customer or profile country).
   * @param toDefaultTime Set true if you ONLY want a time output and use the default value "HH:mm"
   * @param outputFormat Use this if want full control of the output format. Must be a Moment Js compatible format. Examples:
   * "DD/MM/YYYY", "HH:mm", "DD MM YY" etc.
   */
  public formatUtcDateTime(dateTimeStringUtc: string, toDefaultTime?: boolean, toDefaultDate?: boolean, outputFormat?: string): string {
    if (dateTimeStringUtc) {
      if (toDefaultTime) return moment(dateTimeStringUtc).format("HH:mm");
      if (toDefaultDate) return moment(dateTimeStringUtc).format(this.dateFormat);
      if (outputFormat) return moment(dateTimeStringUtc).format(outputFormat);
      return moment(dateTimeStringUtc).format();
    }
    return "";
  }

  /**
   * Converts a formated date time string into a correct ISO 8601 UTC date time string - ready to send to server.
   * @param dateTimeString A date time string in the format DD/MM/YYYY HH:mm (or YYYY-MM-DD HH:mm if Swedish).
   * @param inputFormat The date time format. This string must be a format conforming to Moment Js compatible date times like "DD/MM/YYYY HH:mm" or just
   * the date part. Defaults to the general format in customer's country
   */
  public formatedDateTimeStringToUtcString(dateTimeString: string, inputFormat?: string): string {
    if (isStringNullOrEmpty(dateTimeString)) return null;
    const momentDateTime = moment(dateTimeString, inputFormat ? inputFormat : this.dateTimeFormat);
    if (momentDateTime.isValid()) {
      return momentDateTime.toISOString();
    } else return null;
  }

  //#endregion

  //#region HELPERS
  /**
   * Returns language configurations based on a language id. By default, Danish countryId is used (if null or undefined
   * is passed).
   */
  public getLanguageConfigByCountry(countryId: BiCountryId): LanguageConfig {
    if (!countryId) countryId = BiCountryId.DK;

    switch (countryId) {
      case BiCountryId.DK:
        return {
          dateFormat: "DD/MM/YYYY",
          dateFormatNoYear: "DD/MM",
          dateTimeFormat: "DD/MM/YYYY HH:mm",
          phoneCountryCode: 45,
          minimumPhoneLength: 8,
          maximumPhoneLength: 8,
          phoneLengthLandlineMin: phoneLength_DK,
          phoneLengthLandlineMax: phoneLength_DK,
          zipcodeLength: 4,
          defaultMapCenterLatitude: 56.2639,
          defaultMapCenterLongitude: 9.5018,
          countryCode: "dk"
        };
      case BiCountryId.SE:
        return {
          dateFormat: "YYYY-MM-DD",
          dateFormatNoYear: "MM-DD",
          dateTimeFormat: "YYYY-MM-DD HH:mm",
          phoneCountryCode: 46,
          minimumPhoneLength: phoneLength_SE[0],
          maximumPhoneLength: phoneLength_SE[1],
          phoneLengthLandlineMin: landLineLength_SE[0],
          phoneLengthLandlineMax: landLineLength_SE[1],
          zipcodeLength: 5,
          defaultMapCenterLatitude: 60.12816,
          defaultMapCenterLongitude: 18.6435,
          countryCode: "se"
        };
      case BiCountryId.EN:
        return {
          dateFormat: "DD/MM/YYYY",
          dateFormatNoYear: "DD/MM",
          dateTimeFormat: "DD/MM/YYYY HH:mm",
          phoneCountryCode: 44, // Change to England
          minimumPhoneLength: 8,
          maximumPhoneLength: 8, // Change to England
          phoneLengthLandlineMin: 8, // Change to England
          phoneLengthLandlineMax: 10, // Change to England
          zipcodeLength: 4, // Change to England
          defaultMapCenterLatitude: 56.2639, // Change to England
          defaultMapCenterLongitude: 9.5018, // Change to England
          countryCode: "en"
        };
      case BiCountryId.FI:
        return {
          dateFormat: "DD/MM/YYYY",
          dateFormatNoYear: "DD/MM",
          dateTimeFormat: "DD/MM/YYYY HH:mm",
          phoneCountryCode: 358,
          minimumPhoneLength: phoneLength_FI[0],
          maximumPhoneLength: phoneLength_FI[1],
          phoneLengthLandlineMin: landLineLength_FI[0],
          phoneLengthLandlineMax: landLineLength_FI[1],
          zipcodeLength: 5,
          defaultMapCenterLatitude: 64.6233799,
          defaultMapCenterLongitude: 23.8364001,
          countryCode: "fi"
        };
      case BiCountryId.NO:
        return {
          dateFormat: "YYYY-MM-DD",
          dateFormatNoYear: "MM-DD",
          dateTimeFormat: "YYYY-MM-DD HH:mm",
          phoneCountryCode: 47,
          minimumPhoneLength: phoneLength_NO,
          maximumPhoneLength: phoneLength_NO,
          phoneLengthLandlineMin: phoneLength_NO,
          phoneLengthLandlineMax: phoneLength_NO,
          zipcodeLength: 4,
          defaultMapCenterLatitude: 65.4465118,
          defaultMapCenterLongitude: 16.7209846,
          countryCode: "no"
        };
      case BiCountryId.DE:
        return {
          dateFormat: "DD/MM/YYYY",
          dateFormatNoYear: "DD/MM",
          dateTimeFormat: "DD/MM/YYYY HH:mm",
          phoneCountryCode: PhoneCodeEnum.DE,
          minimumPhoneLength: phoneLength_DE[0],
          maximumPhoneLength: phoneLength_DE[1],
          phoneLengthLandlineMin: phoneLength_DE[0],
          phoneLengthLandlineMax: phoneLength_DE[1],
          zipcodeLength: 4,
          defaultMapCenterLatitude: 54.6683626,
          defaultMapCenterLongitude: 9.2664124,
          countryCode: "de"
        };
    }
  }

  /*
   * Return a Country Or Language Name based on its Id.
   */
  public getLanguageName(languageId: number | string): string {
    if (languageId === BiCountryId.DK || languageId === BiCountryId.DK.toString()) {
      return this.translator.instant("shared.Danish");
    }
    if (languageId === BiCountryId.SE || languageId === BiCountryId.SE.toString()) {
      return this.translator.instant("shared.Swedish");
    }
    if (languageId === BiCountryId.EN || languageId === BiCountryId.EN.toString()) {
      return this.translator.instant("shared.English");
    }
    if (languageId === BiCountryId.FI || languageId === BiCountryId.FI.toString()) {
      return this.translator.instant("shared.Finnish");
    }
    if (languageId === BiCountryId.NO || languageId === BiCountryId.NO.toString()) {
      return this.translator.instant("shared.Norwegian");
    }
  }

  public getCountryName(countryId: BiCountryId) {
    switch (countryId) {
      case BiCountryId.DK:
        return this.translator.instant("shared.Denmark");

      case BiCountryId.SE:
        return this.translator.instant("shared.Sweden");

      case BiCountryId.EN:
        return this.translator.instant("shared.England");

      case BiCountryId.FI:
        return this.translator.instant("shared.Finland");

      case BiCountryId.NO:
        return this.translator.instant("shared.Norway");

      default:
        return 0;
    }
  }

  /**
   * Returns a BiLanguageId corresponding to a 2-letter language code.
   * Note: as the BiLanguageId values corresponds to BiCountryId values, this can also be used for getting a countryID.
   */
  public getBiLanguageFromLanguageCode(languageCode: string) {
    if (languageCode.indexOf("se") !== -1) return BiLanguageId.SE;
    if (languageCode.indexOf("da") !== -1) return BiLanguageId.DK;
    if (languageCode.indexOf("fi") !== -1) return BiLanguageId.FI;
    if (languageCode.indexOf("no") !== -1) return BiLanguageId.NO;
    if (languageCode.indexOf("en") !== -1) return BiLanguageId.EN;
    return BiLanguageId.DK;
  }

  public getAllMonths() {
    const calender = [];
    for (let i = 1; i < 13; i++) {
      calender.push(this.getMonthName(i));
    }
    return calender;
  }

  public getMonthName(monthNumber: number) {
    switch (monthNumber) {
      case 1:
        return this.translator.instant("shared.Month.January");
      case 2:
        return this.translator.instant("shared.Month.February");
      case 3:
        return this.translator.instant("shared.Month.March");
      case 4:
        return this.translator.instant("shared.Month.April");
      case 5:
        return this.translator.instant("shared.Month.May");
      case 6:
        return this.translator.instant("shared.Month.June");
      case 7:
        return this.translator.instant("shared.Month.July");
      case 8:
        return this.translator.instant("shared.Month.August");
      case 9:
        return this.translator.instant("shared.Month.September");
      case 10:
        return this.translator.instant("shared.Month.October");
      case 11:
        return this.translator.instant("shared.Month.November");
      case 12:
        return this.translator.instant("shared.Month.December");
      default:
        return "N/A";
    }
  }

  public getCountryIdByPhoneCode(phoneCode: string) {
    switch (phoneCode) {
      case "+45":
        return BiCountryId.DK;
      case "+44":
        return BiCountryId.EN;
      case "+46":
        return BiCountryId.SE;
      case "+47":
        return BiCountryId.NO;
      case "+49":
        return BiCountryId.DE;
      case "+358":
        return BiCountryId.FI;
    }
  }

  public getDurationString(data: any): any {
    const minuteValue = data as number;
    const totalMinutes = Math.trunc(minuteValue);
    const seconds = Math.trunc((minuteValue - totalMinutes) * 60);
    const minutes = totalMinutes % 60;
    const hours = Math.floor(totalMinutes / 60) % 24;
    const days = Math.floor(totalMinutes / 1440);

    return (
      (days > 0 ? days.toString() + " " + this.translator.instant("shared.Days") + " " : "") +
      (hours > 0 ? hours.toString() + " " + this.translator.instant("shared.Hours") + " " : "") +
      (minutes > 0 ? minutes.toString() + " " + this.translator.instant("shared.Minutes") + " " : "") +
      (seconds > 0 ? seconds.toString() + " " + this.translator.instant("shared.Seconds") : "")
    ).trim();
  }
  //#endregion
}
