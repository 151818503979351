import { ChangeDetectionStrategy, Component, ContentChild, ElementRef, EventEmitter, Input, Output, Renderer2, TemplateRef, ViewChild } from "@angular/core";

@Component({
    selector: "en-expanding-panel",
    templateUrl: "./en-expanding-panel.component.html",
    styleUrls: ["./en-expanding-panel.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class EnExpandingPanelComponent {
  @ContentChild("header") header: TemplateRef<any>;
  @ContentChild("content") content: TemplateRef<any>;
  @ViewChild("contentContainer") contentContainer: ElementRef<HTMLDivElement>;

  @Input() public showCheckbox = false;
  @Input() public ariaLabel: string;
  @Input() public ariaLabelledBy: string;

  @Input() public selected = false;
  @Output() public selectedChange = new EventEmitter<boolean>();

  @Input() public expanded = false;
  @Input() public expandable = true;
  @Output() public expandedChange = new EventEmitter<boolean>();

  constructor(private renderer: Renderer2) {}

  public toggleExpand() {
    this.expanded = !this.expanded;
    this.expandedChange.emit(this.expanded);
    setTimeout(() => this.renderer.setStyle(this.contentContainer.nativeElement, "overflow", this.expanded ? "visible" : "hidden"), this.expanded ? 600 : 0);
  }

  public cancelClick(e: Event) {
    e.stopPropagation();
    e.preventDefault();
  }
}
