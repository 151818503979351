import { NgOptimizedImage } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AppCanActivateGuard } from "@core-sub/routing/guards/en-app-can-activate.guard";
import { WelcomePageComponent } from "@features-sub/welcome-page/welcome-page.component";
import { BiTranslateDirective, BiTranslatePipe } from "@globals/bi-translate";
import { ComponentContainerDirective } from "@globals/directives/component-container.directive";
import { RouteNamesEn } from "@shared-sub/classes/RouteNamesEn";
import { SharedModule } from "@shared-sub/shared.module";
import { DialogModule } from "primeng/dialog";
import { DynamicDialogModule } from "primeng/dynamicdialog";
import { EnTermsAndConditionsModule } from "../features-shared/terms-and-conditions/en-terms-and-conditions.module";
import { AboutPageComponent } from "./about-page/en-about-page.component";
import { EnrollmentStepsModule } from "./enrollment-steps/enrollment-steps.module";
import { PrivacyPolicyMainComponent } from "./privacy-policy/privacy-policy-main.component";

const routes: Routes = [
  { path: RouteNamesEn.aboutPage, component: AboutPageComponent },
  { path: RouteNamesEn.privacyPolicy, component: PrivacyPolicyMainComponent },
  {
    path: RouteNamesEn.termsAndConditions,
    loadChildren: () => import("../features-shared/terms-and-conditions/en-terms-and-conditions.module").then(m => m.EnTermsAndConditionsModule)
  },
  {
    path: RouteNamesEn.mySenders,
    loadChildren: () => import("./my-senders/my-senders.module").then(m => m.MySendersModule),
    canActivate: [AppCanActivateGuard]
  },
  {
    path: RouteNamesEn.login,
    loadChildren: () => import("./login/en-login.module").then(m => m.EnLoginModule)
  },
  {
    path: RouteNamesEn.unsubscribeSender,
    loadChildren: () => import("./unsubscribe-sender/unsubscribe-sender.module").then(m => m.UnsubscribeSenderModule)
  },
  { path: "**", component: WelcomePageComponent }
];

@NgModule({
  declarations: [WelcomePageComponent, AboutPageComponent, PrivacyPolicyMainComponent],
  imports: [
    SharedModule,
    EnTermsAndConditionsModule,
    NgOptimizedImage,
    RouterModule.forChild(routes),
    BiTranslatePipe,
    BiTranslateDirective,
    EnrollmentStepsModule,
    DynamicDialogModule,
    DialogModule,
    ComponentContainerDirective
  ],
  exports: [RouterModule, EnrollmentStepsModule]
})
export class FeaturesModule {}
