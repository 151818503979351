import { Pipe, PipeTransform } from "@angular/core";
import { formatZipCode } from "@globals/helper-functions";
import { EnrollmentAddressDto } from "@globals/openapi-models/model/enrollmentAddressDto";

@Pipe({
    name: "addressFormat",
    standalone: false
})
export class EnAddressFormatPipe implements PipeTransform {
  transform(address: EnrollmentAddressDto): string {
    if (address)
      return `${address.streetName + (address.streetAlias ? " (" + address.streetAlias + ")" : "")}  ${address.houseNumberDisplayName}, ${formatZipCode(address.countryId, address.zipCode)} ${
        address.city
      }`;
    else return "";
  }
}
