import { NgModule } from "@angular/core";
import { BiHighlightSpacesPipe } from "./bi-highlight-spaces-pipe";
import { BiYearMonthPipe } from "./bi-year-month.pipe";

const pipes = [BiYearMonthPipe, BiHighlightSpacesPipe];

@NgModule({
  declarations: [...pipes],
  exports: pipes
})
export class GlobalPipesModule {}
