import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostBinding, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { GlobalStateAndEventsServiceEn } from "@core-sub/en-global-state-and-events.service";
import { EnrollmentAuthenticationService } from "@core-sub/security/enrollment-authentication.service";
import { EnrollmentService } from "@core-sub/services/enrollment.service";
import { EnrolleeInfoTrackingService } from "@core-sub/utility-services/enrollee-info-tracking.service";
import { BiCountryId } from "@globals/enums/BiLanguageAndCountryId";
import { getCountryCodeByCountryId } from "@globals/helper-functions";
import { EnrollmentDto } from "@globals/openapi-models/model/enrollmentDto";
import { SignUpEnrolleeDtoExt } from "@models-sub/ext/SignUpEnrolleeDtoExt";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { FadeAnimations } from "@shared-sub/animations/fade-animations";
import { SelectSendersBase } from "@shared-sub/classes/BaseClasses/SelectSendersBase";
import { RouteNamesEn } from "@shared-sub/classes/RouteNamesEn";
import { DialogService } from "primeng/dynamicdialog";
import { catchError, map, of, take } from "rxjs";
import { EnrollmentStepsSharedService } from "../enrollment-steps.shared.service";
import { EnrollmentStep } from "../EnrollmentStep";

@UntilDestroy()
@Component({
  selector: "sender-selection",
  templateUrl: "./sender-selection.component.html",
  styleUrls: ["./sender-selection.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [FadeAnimations.fadeIn],
  standalone: false
})
export class SenderSelectionComponent extends SelectSendersBase implements OnInit, EnrollmentStep {
  public termsAndConditionsAccepted = false;
  public showTermsAndConditions = false;
  public currentEnrollmentState = this.enrollmentStepsSharedService.getCurrentStateValue();
  public currentStateValue = this.globalStateService.getCurrentStateValue();
  public countryId: BiCountryId = BiCountryId.Default;

  private scrollingArea: ElementRef<HTMLDivElement>;

  @HostBinding("@fadeIn") get fadeInHost() {
    return true;
  }
  @HostBinding("class.en-dialog-content-component") get hostClass() {
    return true;
  }

  constructor(
    private enrollmentStepsSharedService: EnrollmentStepsSharedService,
    private globalStateService: GlobalStateAndEventsServiceEn,
    protected enrollmentService: EnrollmentService,
    protected cd: ChangeDetectorRef,
    private router: Router,
    protected dialogService: DialogService,
    protected authService: EnrollmentAuthenticationService,
    private enrolleeTracker: EnrolleeInfoTrackingService
  ) {
    super(enrollmentService, authService, cd, dialogService);
  }

  public ngOnInit() {
    this.preselectSenders = true;
    this.countryId = this.globalStateService.getCurrentStateValue().countryId;
    super.onInit();
  }

  public setScrollingArea(area: ElementRef<HTMLDivElement>) {
    this.scrollingArea = area;
  }

  public onBackClicked(): boolean {
    return this.overrideTermsAndConditions();
  }

  public onAbortClicked(): boolean {
    return this.overrideTermsAndConditions();
  }

  private overrideTermsAndConditions() {
    if (this.showTermsAndConditions) {
      this.showTermsAndConditions = false;
      this.cd.detectChanges();
      return true;
    }

    return false;
  }

  public goToStep2() {
    this.router.navigate([RouteNamesEn.enrollmentStepsRoutes.main, RouteNamesEn.enrollmentStepsRoutes.step2]);
  }

  public onSubscribeClicked() {
    if (this.working) return;
    this.working = true;

    const trackingInfo = this.enrolleeTracker.getTrackingInfo();
    const dto: SignUpEnrolleeDtoExt = {
      enrolleeName: this.currentEnrollmentState.address.enrolleeName,
      countryCode: +getCountryCodeByCountryId(this.currentStateValue.countryId),
      uiLanguageId: +this.currentStateValue.selectedLanguage,
      landingPageUrl: trackingInfo.enrolleeLandingPageUrl,
      referrerPageUrl: trackingInfo.enrolleeReferrerUrl,
      enrollments: this.senders
        .filter(s => s.selected)
        .map(
          s =>
            <EnrollmentDto>{
              senderId: s.id,
              kvhx: this.currentEnrollmentState.address.kvhx
            }
        )
    };

    const newState = {
      ...this.enrollmentStepsSharedService.getCurrentStateValue(),
      unselectedSenders: this.senders.filter(s => !s.selected)
    };
    this.enrollmentStepsSharedService.setState(newState);

    this.enrollmentService
      .signUp(dto)
      .pipe(
        untilDestroyed(this),
        catchError((err: HttpErrorResponse) => {
          this.working = false;
          this.cd.detectChanges();
          // 409 = conflict => enrollee already signed up
          if (err.status === 409) return of(true);
          else throw new Error(err.message);
        })
      )
      .subscribe(hadConflict => {
        if (!hadConflict) this.authService.updateEnrolleeCreationDate();
        this.router.navigate([RouteNamesEn.mySenders], { queryParams: { firstVisit: "1" } });
      });
  }

  public displayTermsAndConditions() {
    this.scrollingArea.nativeElement.scrollTo({
      behavior: "auto",
      top: 0,
      left: 0
    });
    this.showTermsAndConditions = true;
  }

  //#region Base class implementations
  protected getSelectedAddress() {
    return this.enrollmentStepsSharedService.state$.pipe(
      take(1),
      map(s => s.address)
    );
  }

  protected onFinish = () => this.onSubscribeClicked();

  //#endregion
}
