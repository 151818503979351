import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, OnDestroy, Output, ViewChild } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { debounceTime, fromEvent } from "rxjs";

@UntilDestroy()
@Component({
    selector: "en-dialog-frame",
    templateUrl: "./en-dialog-frame.component.html",
    styleUrls: ["./en-dialog-frame.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class EnDialogFrameComponent implements AfterViewInit, OnDestroy {
  @Output() onBack = new EventEmitter();
  @Output() onClose = new EventEmitter();
  @Output() onScrollable = new EventEmitter<ElementRef<HTMLDivElement>>();

  private _scrollableArea: ElementRef<HTMLDivElement>;
  @ViewChild("scrollableArea") set scrollableArea(value: ElementRef<HTMLDivElement>) {
    this._scrollableArea = value;
    this.onScrollable.emit(value);

    const scrollEvent$ = fromEvent(value.nativeElement, "scroll", {
      capture: true
    });
    scrollEvent$.pipe(untilDestroyed(this), debounceTime(100)).subscribe(e => {
      const div = e.srcElement as HTMLElement;
      if (div.scrollTop === 0) div.scrollTop = 1;
      if (div.scrollTop === div.scrollHeight - div.clientHeight) div.scrollTop -= 1;
    });
  }

  ngOnDestroy(): void {
    enableBodyScroll(this._scrollableArea.nativeElement);
  }

  public ngAfterViewInit() {
    // Disable all scrolling except the  main  scrollable area. Only do this for non-touch  devices (allowTouchMove).
    disableBodyScroll(this._scrollableArea.nativeElement, {
      allowTouchMove: (_el: HTMLElement) => true
    });
    setTimeout(() => {
      document.querySelector(".en-dialog-frame")?.classList.remove("zoom-zero");
    }, 100);
  }

  public close() {
    this.onClose.emit();
  }

  public back() {
    this.onBack.emit();
  }
}
