import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { BiTranslateDirective, BiTranslatePipe } from "@globals/bi-translate";
import { ComponentContainerDirective } from "@globals/directives/component-container.directive";
import { RouteNamesEn } from "@shared-sub/classes/RouteNamesEn";
import { SharedModule } from "@shared-sub/shared.module";
import { EnTermsAndConditionsMainComponent } from "./en-terms-and-conditions-main.component";

@NgModule({
  declarations: [EnTermsAndConditionsMainComponent],
  imports: [
    SharedModule,
    BiTranslatePipe,
    BiTranslateDirective,
    RouterModule.forChild([
      {
        path: RouteNamesEn.termsAndConditions,
        component: EnTermsAndConditionsMainComponent
      }
    ]),
    ComponentContainerDirective
  ],
  exports: [EnTermsAndConditionsMainComponent, RouterModule]
})
export class EnTermsAndConditionsModule {}
