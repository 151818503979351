import { Injectable } from "@angular/core";
import { BiTranslateService } from "@globals/bi-translate";
import { BiStore } from "@globals/classes/BiStore";
import { BiCountryId, BiLanguageId } from "@globals/enums/BiLanguageAndCountryId";
import { cloneObject } from "@globals/helper-functions";
import { UntilDestroy } from "@ngneat/until-destroy";
import { take } from "rxjs";
import { GlobalStateEn } from "./GlobalStateEn";

/**
 * Class for defining global events used in whole application. Using the power of RxJs!
 */
@UntilDestroy({ checkProperties: true })
@Injectable({
  providedIn: "root"
})
export class GlobalStateAndEventsServiceEn extends BiStore<GlobalStateEn> {
  public constructor(translator: BiTranslateService) {
    super(new GlobalStateEn([]));

    // When translations are ready, we can set the country names
    translator.onLangChange.pipe(take(1)).subscribe(() => {
      this.setState({
        ...this.state.value,
        countries: [
          {
            name: translator.instant("shared.Denmark"),
            countryId: BiCountryId.DK
          },
          {
            name: translator.instant("shared.Sweden"),
            countryId: BiCountryId.SE
          },
          {
            name: translator.instant("shared.Finland"),
            countryId: BiCountryId.FI
          },
          {
            name: translator.instant("shared.Norway"),
            countryId: BiCountryId.NO
          }
        ]
      });
    });
  }

  //#endregion

  /**
   * Checks the screen width and does the following:
   * if < 576, top menu and overlay should both be hidden
   * if >= 576, show both
   */
  public showHideTopMenuAndOverlay() {
    // Depending on screen size, show/hide top menu bar and the overlay
    if (window.innerWidth < 576 && (this.state.value.showTopMenubar || this.state.value.showOverlay))
      this.state.next({
        ...this.state.value,
        showTopMenubar: false,
        showOverlay: false
      });
    else if (window.innerWidth >= 576 && (!this.state.value.showTopMenubar || !this.state.value.showOverlay)) {
      this.state.next({
        ...this.state.value,
        showTopMenubar: true,
        showOverlay: true
      });
    }
  }

  public setLanguageAndOrCountry(languageId?: BiLanguageId, countryId?: BiCountryId) {
    if (!languageId && !countryId) throw new Error("Provide either a languageId or a countryId!");
    const state2Set = cloneObject(this.state.value);
    if (languageId) state2Set.selectedLanguage = languageId;
    if (countryId) state2Set.countryId = countryId;
    this.state.next(state2Set);
  }
}
