import { Location } from "@angular/common";
import { PrivacyPolicyFiComponent } from "./en-privacy-policy-fi.component";
import { ChangeDetectionStrategy, Component, OnInit, Type, ViewChild } from "@angular/core";
import { GlobalStateAndEventsServiceEn } from "@core-sub/en-global-state-and-events.service";
import { ComponentContainerDirective } from "@globals/directives/component-container.directive";
import { BiCountryId } from "@globals/enums/BiLanguageAndCountryId";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { DisplayTextBaseComponent } from "@shared-sub/classes/BaseClasses/DisplayTextComponentBase";
import { filter, map, take } from "rxjs";
import { PrivacyPolicyDkComponent } from "./en-privacy-policy-dk.component";
import { PrivacyPolicySeComponent } from "./en-privacy-policy-se.component";

@UntilDestroy()
@Component({
    selector: "privacy-policy-main.component.ts",
    template: ` <en-dialog-frame (onClose)="goBack()" (onBack)="goBack()">
    <div class="w-full pb-3">
      <h1 class="text-center pb-4" translate>shared.PrivacyPolicy</h1>
      <ng-template componentContainer></ng-template>
    </div>
  </en-dialog-frame>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PrivacyPolicyMainComponent implements OnInit {
  @ViewChild(ComponentContainerDirective, { static: true })
  componentContainer!: ComponentContainerDirective;

  constructor(private location: Location, private globalStateService: GlobalStateAndEventsServiceEn) {}

  public ngOnInit() {
    this.globalStateService.state$
      .pipe(
        untilDestroyed(this),
        filter(s => s.countryId != null && s.selectedLanguage != null),
        take(1),
        map(s => {
          return { countryId: s.countryId, languageId: s.selectedLanguage };
        })
      )
      .subscribe(countryAndLang => {
        let component2Load: Type<DisplayTextBaseComponent> = PrivacyPolicyDkComponent;
        // First find out what component to load. First when the ngAfterViewInit has been called, we can access viewchild.
        // Dynamically insert the correct component depending on country
        if (countryAndLang.countryId === BiCountryId.SE) component2Load = PrivacyPolicySeComponent;
        else if (countryAndLang.countryId === BiCountryId.FI) component2Load = PrivacyPolicyFiComponent;

        const componentRef = this.componentContainer.viewContainerRef.createComponent(component2Load);
        componentRef.instance.languageId = countryAndLang.languageId;
      });
  }

  public goBack() {
    this.location.back();
  }
}
