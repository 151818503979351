import { HttpClient } from "@angular/common/http";
import { Provider } from "@angular/core";
import { BiHttpTranslateLoader } from "@globals/classes/BiHttpTranslateLoader";
import {
  DEFAULT_LANGUAGE,
  FakeMissingTranslationHandler,
  ISOLATE_TRANSLATE_SERVICE,
  MissingTranslationHandler,
  TranslateCompiler,
  TranslateDefaultParser,
  TranslateFakeCompiler,
  TranslateLoader,
  TranslateParser,
  TranslateService,
  TranslateStore,
  USE_DEFAULT_LANG,
  USE_EXTEND
} from "@ngx-translate/core";

/**
 * ***** Use this instead of "TranslateModule.forRoot"! ******
 * Providers copied from NGX Translate library's TranslateModule.forRoot method. We do NOT want to use
 * that method as we would then import the "TranslateModule" leading to duplicate translate pipes and directives
 * when we use BiTranslateDirective and BiTranslatePipe (and we should always use those!).
 */
export const biTranslateProviders = <Provider[]>[
  {
    provide: TranslateLoader,
    useClass: BiHttpTranslateLoader,
    deps: [HttpClient]
  },
  { provide: TranslateCompiler, useClass: TranslateFakeCompiler },
  { provide: TranslateParser, useClass: TranslateDefaultParser },
  { provide: MissingTranslationHandler, useClass: FakeMissingTranslationHandler },
  TranslateStore,
  { provide: ISOLATE_TRANSLATE_SERVICE, useValue: false },
  { provide: USE_DEFAULT_LANG, useValue: false },
  { provide: USE_EXTEND, useValue: false },
  { provide: DEFAULT_LANGUAGE, useValue: undefined },
  TranslateService
];
