import { ChangeDetectionStrategy, Component, input, output, signal, viewChild } from "@angular/core";
import { EnrollmentService } from "@core-sub/services/enrollment.service";
import { BiTranslateService } from "@globals/bi-translate";
import { BiCountryId } from "@globals/enums/BiLanguageAndCountryId";
import { SenderExt } from "@models-sub/ext/SenderExt";
import differenceWith from "lodash-es/differenceWith";
import { AutoComplete, AutoCompleteCompleteEvent } from "primeng/autocomplete";
import { map } from "rxjs";

@Component({
    selector: "en-sender-search",
    templateUrl: "./en-sender-search.component.html",
    styles: ["::ng-deep .p-autocomplete-loader{margin-right: 20px;}"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class EnSenderSearchComponent {
  senderSearchControl = viewChild<AutoComplete>("senderSearch");
  onSenderSelected = output<SenderExt>();
  onSendATipClicked = output<any>();
  existingSenders = input<Array<SenderExt>>();
  countryId = input<BiCountryId>(BiCountryId.Default);
  kvhx = input<string>();
  title = input<string>();
  ariaLabel = input<string>();
  ariaLabelledBy = input<string>();

  constructor(
    private enrollmentService: EnrollmentService,
    private translator: BiTranslateService
  ) {}

  public suggestionsSender = signal<SenderExt[]>([]);

  public autoCompleteSenderSearch(e: AutoCompleteCompleteEvent) {
    const callback = senders => {
      if (this.senderSearchControl()) this.senderSearchControl().loading = true; // There is some bug in the Prime Ng AutopComplete cusing this flag not to be correctly updated.
      if (senders.length === 0) {
        return [
          {
            id: "0",
            name: this.translator.instant("enrollment.NoResultsFound")
          }
        ];
      } else {
        return senders;
      }
    };

    this.enrollmentService
      .searchSenders(this.countryId(), this.kvhx(), e.query as string)
      .pipe(
        map(result => {
          if (this.existingSenders()) return differenceWith(result, this.existingSenders(), (sender, exsistingSender) => sender.id === exsistingSender.id);
          return result;
        }),
        map(callback)
      )
      .subscribe(s => this.suggestionsSender.set(s));
  }

  public senderSelected(e?: SenderExt) {
    if (e && e.id !== "0") {
      this.onSenderSelected.emit(e);
      this.senderSearchControl().modelValue.set(null);
    } else {
      this.senderSearchControl().writeValue("");
    }
  }

  public sendATipClicked(e: Event) {
    e.preventDefault();
    e.stopImmediatePropagation();
    e.stopPropagation();
    this.onSendATipClicked.emit(e);
    this.senderSearchControl().writeValue("");
    this.senderSearchControl().hide();
  }

  public scrollOneDown(e: Event & { overlay?: HTMLElement }) {
    if (e?.overlay) e.overlay.getElementsByClassName("p-autocomplete-panel")[0].scrollTop = 1;
  }
}
