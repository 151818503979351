import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, OnInit } from "@angular/core";
import { EnrollmentService } from "@core-sub/services/enrollment.service";
import { EnNotificationConfig, EnToastNotificationService } from "@core-sub/utility-services/en-toast-notification.service";
import { BiTranslateService } from "@globals/bi-translate";
import { PageNames } from "@globals/openapi-models/model/pageNames";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { finalize } from "rxjs";

@UntilDestroy()
@Component({
    selector: "send-a-tip",
    templateUrl: "./send-a-tip-dialog-content.component.html",
    styles: [":host{display: block; padding: 0 .5rem 1rem}"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class SendATipDialogContentComponent implements OnInit {
  public kvhx: string;
  public tipText: string;

  @HostBinding("class.en-dialog-content-component") get hostClass() {
    return true;
  }

  constructor(
    private enrollmentService: EnrollmentService,
    private dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private cd: ChangeDetectorRef,
    private notifier: EnToastNotificationService,
    private readonly translator: BiTranslateService
  ) {}

  public ngOnInit() {
    this.kvhx = this.config.data.address.kvhx;
  }

  public onCancelClicked() {
    this.dialogRef.close();
  }

  public onSendClicked() {
    this.enrollmentService
      .sendATip(PageNames.EnrollmentModule, this.tipText, this.kvhx)
      .pipe(
        untilDestroyed(this),
        finalize(() => this.cd.detectChanges())
      )
      .subscribe(() => {
        this.notifier.createNotification(new EnNotificationConfig(undefined, undefined, this.translator.instant("enrollment.TipReceipt")));
        this.dialogRef.close();
      });
  }
}
