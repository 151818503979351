import { Location } from "@angular/common";
import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { BiTranslateService } from "@globals/bi-translate";
import { convertNewlinesToHtmlTags } from "@globals/helper-functions";

@Component({
    selector: "en-about-page",
    template: `<en-dialog-frame (onClose)="goBack()" (onBack)="goBack()">
    <div class="w-full pb-3">
      <h1 class="text-center pb-4" translate>enrollment.AboutPageTitle</h1>
      <div [innerHTML]="text"></div>
    </div>
  </en-dialog-frame>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class AboutPageComponent implements OnInit {
  constructor(
    private location: Location,
    protected sanitizer: DomSanitizer,
    private translator: BiTranslateService
  ) {}

  public text: SafeHtml;

  public ngOnInit() {
    this.text = this.sanitizer.bypassSecurityTrustHtml(convertNewlinesToHtmlTags(this.translator.instant("enrollment.AboutPageContent")));
  }

  public goBack() {
    this.location.back();
  }
}
