import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, Inject, OnInit } from "@angular/core";
import { Router, RouterOutlet } from "@angular/router";
import { GlobalStateAndEventsServiceEn } from "@core-sub/en-global-state-and-events.service";
import { EnrollmentAuthenticationService } from "@core-sub/security/enrollment-authentication.service";
import { EnrolleeService } from "@core-sub/services/enrollee.service";
import { EnrollmentService } from "@core-sub/services/enrollment.service";
import { EnLocalizationHelperService } from "@core-sub/utility-services/en-localization-helper.service";
import { BiCountryId, BiLanguageId } from "@globals/enums/BiLanguageAndCountryId";
import { getLanguageCodeByLanguageId, isRunningInIE, isStringNullOrEmpty } from "@globals/helper-functions";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { RouteNamesEn } from "@shared-sub/classes/RouteNamesEn";
import { catchError, map, Observable, of, switchMap, take } from "rxjs";
import { appRouterAnimations } from "./app-router-animations";
import { DOCUMENT } from "@angular/common";
import { getLanguageCodeByHostName } from "../main";
import { EnrolleeInfoTrackingService } from "@core-sub/utility-services/enrollee-info-tracking.service";
import { BiSeoService } from "@globals/services/bi-seo.service";

@UntilDestroy()
@Component({
    selector: "bi-subscription-app",
    templateUrl: "./subscription-app.component.html",
    styleUrls: ["./subscription-app.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [appRouterAnimations],
    standalone: false
})
export class SubscriptionAppComponent implements OnInit {
  public isInitializing = true;
  public showToolbar$: Observable<boolean>;
  public showOverlay: boolean;
  public showIFrameMessage: boolean = window.top !== window;

  public get loggedIn() {
    return this.authService.enrollmentTokenModel !== null;
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private globalStateService: GlobalStateAndEventsServiceEn,
    private cd: ChangeDetectorRef,
    private localizor: EnLocalizationHelperService,
    private router: Router,
    private enrollmentService: EnrollmentService,
    private enrolleeTracker: EnrolleeInfoTrackingService,
    private enrolleeService: EnrolleeService,
    private authService: EnrollmentAuthenticationService,
    private seoService: BiSeoService
  ) {}

  public accessToken: string;

  public ngOnInit() {
    this.seoService.initialize(true);
    let senderId = "";
    let senderSlug: string;

    const url = new URL(window.location.href);

    if (url.searchParams.get("sender")) senderId = url.searchParams.get("sender");
    if (url.pathname !== "/" && !RouteNamesEn.isRouteInUse(url.pathname)) {
      senderSlug = url.pathname.substring(1);
    }

    // get language from session to handle refreshing
    let lang = this.authService.languageCode();

    const getSender$ = !isStringNullOrEmpty(senderId) ? this.enrollmentService.getSenderBySenderId(senderId) : senderSlug ? this.enrollmentService.getSenderBySlug(senderSlug) : of(undefined);
    const countryByDomain$ = this.enrollmentService.getInitialData();

    getSender$
      .pipe(
        untilDestroyed(this),
        take(1),
        catchError(() => of(undefined)),
        switchMap(sender =>
          countryByDomain$.pipe(
            map(data => {
              return { sender, countryId: data.countryId };
            })
          )
        )
      )
      .subscribe(senderAndCountryId => {
        if (senderAndCountryId.sender?.customerId) {
          this.authService.saveSender(senderAndCountryId.sender);
          if (!lang) lang = getLanguageCodeByLanguageId(senderAndCountryId.sender.countryId);
          this.finishInitialization(senderAndCountryId.sender.countryId, lang);
        } else {
          this.authService.saveSender(null);
          if (!lang) lang = getLanguageCodeByLanguageId(senderAndCountryId.countryId as number);
          this.finishInitialization(senderAndCountryId.countryId, lang);
        }
      });
  }

  private finishInitialization(country: BiCountryId, languageCode: string) {
    if (!languageCode) languageCode = getLanguageCodeByHostName();

    // Default to Danish/DK
    if (!languageCode) languageCode = "da";

    this.onChangeLanguage(this.localizor.getBiLanguageFromLanguageCode(languageCode), country, true);

    this.showToolbar$ = this.globalStateService.state$.pipe(
      untilDestroyed(this),
      map(s => s.showTopMenubar)
    );

    this.globalStateService.state$
      .pipe(
        untilDestroyed(this),
        map(s => s.showOverlay)
      )
      .subscribe(show => {
        this.showOverlay = show;
        setTimeout(() => {
          this.cd.detectChanges();
        }, 0);
      });

    this.isInitializing = false;

    return { country, lang: languageCode };
  }

  /**
   * Helper for returning the current app route state. This is purely used for animation purposes, making it possible to animate between
   * specific routes. The "state" is a property set on the activatedRouteData, which you can set when specifying a route.
   * @param routerOutlet reference to the root router outlet object in the view/template
   */
  getAppState(routerOutlet: RouterOutlet) {
    // Shitty IE throws error when running app animations so we don't run them in that browser
    if (!isRunningInIE()) return routerOutlet.activatedRouteData.state;

    return undefined;
  }

  // ==== Listeners for when user closes the browser window or tab
  @HostListener("window: unload", ["$event"])
  unloadHandler(_event) {
    this.enrolleeTracker.clearTrackingInfo();
  }

  @HostListener("window: beforeunload", ["$event"])
  beforeUnloadHandler(_event) {
    this.enrolleeTracker.clearTrackingInfo();
  }
  //===============

  public onChangeLanguage(language: BiLanguageId, country?: BiCountryId, isAppLoadTime?: boolean) {
    this.localizor.setLanguage(language);
    this.globalStateService.setLanguageAndOrCountry(language, country);

    const lang = getLanguageCodeByLanguageId(language);

    this.document.documentElement.lang = lang;

    this.authService.saveLanguage(getLanguageCodeByLanguageId(language));

    // If app is being loaded right now, there is no reason to update language on server as there hasn't actually been any change
    if (!isAppLoadTime) {
      // If logged in and there's an associated enrollee, update language of this enrollee
      if (this.authService.enrollmentTokenModel && this.authService.enrollmentTokenModel.enrolleeCreationDate) {
        // We must reload after this in order for the "Cookie Information"-popup to have its language updated, too
        this.enrolleeService
          .updateLanguage(language)
          .pipe(untilDestroyed(this), take(1))
          .subscribe(() => window.location.reload());
      } else window.location.reload();
    }
  }

  public showPrivacyPolicy() {
    this.router.navigate([RouteNamesEn.privacyPolicy]);
  }

  public onLogin() {
    this.router.navigate([RouteNamesEn.login]);
  }

  public onLogout() {
    this.enrollmentService.clearMySendersModel();
    this.enrolleeService.clearState();
    this.authService.logout();
    this.router.navigate([""]);
  }

  public onMainLogoClicked() {
    if (this.authService.enrollmentTokenModel) this.router.navigate([RouteNamesEn.mySenders]);
    else this.router.navigate([""]);
  }
}
