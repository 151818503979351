import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BiTranslateDirective, BiTranslatePipe } from "@globals/bi-translate";
import { ComponentContainerDirective } from "@globals/directives/component-container.directive";
import { BiToBrTagsPipe } from "@globals/pipes/bi-to-br-tags.pipe";
import { GlobalPipesModule } from "@globals/pipes/global-pipes.module";
import { AccordionModule } from "primeng/accordion";
import { AutoCompleteModule } from "primeng/autocomplete";
import { ButtonModule } from "primeng/button";
import { CheckboxModule } from "primeng/checkbox";
import { IconFieldModule } from "primeng/iconfield";
import { InputIconModule } from "primeng/inputicon";
import { InputNumberModule } from "primeng/inputnumber";
import { InputTextModule } from "primeng/inputtext";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { SplitButtonModule } from "primeng/splitbutton";
import { EnAddressComponent } from "./components/en-address/en-address.component";
import { EnDialogFrameComponent } from "./components/en-dialog-frame/en-dialog-frame.component";
import { EnExpandingPanelComponent } from "./components/en-expanding-panel/en-expanding-panel.component";
import { EnLanguageSelectorComponent } from "./components/en-language-selector/en-language-selector.component";
import { EnSenderSearchComponent } from "./components/en-sender-search/en-sender-search.component";
import { EnSenderComponent } from "./components/en-sender/en-sender.component";
import { EnSpinnerComponent } from "./components/en-spinner/en-spinner.component";
import { EnTwoButtonsComponent } from "./components/en-two-buttons/en-two-buttons.component";
import { EnPipesModule } from "./pipes/en-pipes.module";

const primeNgModules = [
  SplitButtonModule,
  ButtonModule,
  ProgressSpinnerModule,
  InputTextModule,
  InputNumberModule,
  AutoCompleteModule,
  AccordionModule,
  CheckboxModule,
  IconFieldModule,
  InputIconModule
];

@NgModule({
  imports: [CommonModule, EnPipesModule, BiToBrTagsPipe, GlobalPipesModule, ComponentContainerDirective, ...primeNgModules, FormsModule, ReactiveFormsModule, BiTranslatePipe, BiTranslateDirective],
  declarations: [
    EnLanguageSelectorComponent,
    EnSenderSearchComponent,
    EnSenderComponent,
    EnAddressComponent,
    EnDialogFrameComponent,
    EnExpandingPanelComponent,
    EnSpinnerComponent,
    EnTwoButtonsComponent
  ],
  exports: [
    EnSpinnerComponent,
    EnLanguageSelectorComponent,
    ButtonModule,
    InputTextModule,
    EnSenderSearchComponent,
    EnSenderComponent,
    EnAddressComponent,
    EnDialogFrameComponent,
    EnExpandingPanelComponent,
    EnTwoButtonsComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class SharedModule {}
