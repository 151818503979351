import { ChangeDetectionStrategy, Component, ContentChild, Input, TemplateRef } from "@angular/core";
import { EnrollmentAddressDto } from "@globals/openapi-models/model/enrollmentAddressDto";

@Component({
    selector: "en-address",
    templateUrl: "./en-address.component.html",
    styleUrls: ["./en-address.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class EnAddressComponent {
  @Input() address: EnrollmentAddressDto;
  @ContentChild("actions") actions: TemplateRef<any>;
}
