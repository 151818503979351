import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

/**
 * Component for displaying two buttons next to each other. Used
 */
@Component({
    selector: "en-two-buttons",
    templateUrl: "./en-two-buttons.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class EnTwoButtonsComponent implements OnInit {
  @Input() leftButtonLabel: string;
  @Input() rightButtonLabel: string;
  /**
   * Color of the left button. Default "blue-border"
   */
  @Input() leftButtonColor: "blue-border" | "blue" | "red" = "blue-border";

  /**
   * Color of the right button. Default is "blue"
   */
  @Input() rightButtonColor: "blue-border" | "blue" | "red" = "blue";
  @Output() onLeftButtonClicked = new EventEmitter<any>();
  @Output() onRightButtonClicked = new EventEmitter<any>();

  public rightButtonClass: string;
  public leftButtonClass = "p-button-outlined";

  public ngOnInit() {
    if (this.leftButtonColor !== "blue-border") this.leftButtonClass = this.leftButtonColor === "blue" ? "" : "p-button-warning";

    if (this.rightButtonColor !== "blue") this.rightButtonClass = this.rightButtonColor === "blue-border" ? "p-button-outlined" : "p-button-warning";
  }
}
