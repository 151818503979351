import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { BiLanguageId } from "@globals/enums/BiLanguageAndCountryId";
import { FadeAnimations } from "@shared-sub/animations/fade-animations";
import { LanguageSelectItem } from "./LanguageSelectItem";

@Component({
    selector: "en-language-selector",
    templateUrl: "./en-language-selector.component.html",
    styleUrls: ["./en-language-selector.component.scss"],
    animations: [FadeAnimations.fadeInDown],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class EnLanguageSelectorComponent implements OnInit {
  @Output() onLanguageSelected = new EventEmitter<BiLanguageId>();

  @Input() defaultLanguage: BiLanguageId;
  public selectableLanguages: Array<LanguageSelectItem>;
  public selectedLanguage: LanguageSelectItem;

  public isOpen: boolean;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.selectableLanguages = [
      {
        translationKey: "shared.Danish",
        id: BiLanguageId.DK,
        iconSrc: "/StaticFiles/images/flag-icons/dk.png"
      },
      {
        translationKey: "shared.Swedish",
        id: BiLanguageId.SE,
        iconSrc: "/StaticFiles/images/flag-icons/se.png"
      },
      {
        translationKey: "shared.English",
        id: BiLanguageId.EN,
        iconSrc: "/StaticFiles/images/flag-icons/en.png"
      },
      {
        translationKey: "shared.Finnish",
        id: BiLanguageId.FI,
        iconSrc: "/StaticFiles/images/flag-icons/fi.png"
      },
      {
        translationKey: "shared.Norwegian",
        id: BiLanguageId.NO,
        iconSrc: "/StaticFiles/images/flag-icons/no.png"
      }
    ];

    if (!this.defaultLanguage) this.selectedLanguage = this.selectableLanguages[0];
    else {
      this.selectedLanguage = this.selectableLanguages.find(sl => sl.id === this.defaultLanguage);
    }

    this.setClickOutsideEventHandler();
  }

  public languageSelected(languageItem: LanguageSelectItem) {
    this.selectedLanguage = languageItem;
    this.onLanguageSelected.emit(languageItem.id);
    this.isOpen = false;
    this.cd.detectChanges();
  }

  public setSelectedLanguage(val: BiLanguageId) {
    if (val !== this.selectedLanguage.id) {
      this.selectedLanguage = this.selectableLanguages.find(l => l.id === val);
      this.cd.detectChanges();
    }
  }

  /**
   * Sets up eventhandler that detects if user clicked outside the dropdown content while it is open.
   * It should be closed in that case.
   */
  private setClickOutsideEventHandler() {
    window.addEventListener("click", e => {
      let clickedEl = e.target as HTMLElement;
      while (clickedEl) {
        if (clickedEl.id === "en-language-selector") return;

        clickedEl = clickedEl.parentNode as HTMLElement;
      }
      // Clicked outside => close!
      this.isOpen = false;
      this.cd.detectChanges();
    });
  }
}
