import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { GlobalStateAndEventsServiceEn } from "@core-sub/en-global-state-and-events.service";
import { EnrollmentAuthenticationService } from "@core-sub/security/enrollment-authentication.service";
import { EnrolleeService } from "@core-sub/services/enrollee.service";
import { EnrollmentService } from "@core-sub/services/enrollment.service";
import { EnLocalizationHelperService } from "@core-sub/utility-services/en-localization-helper.service";
import { BiTranslateService } from "@globals/bi-translate";
import { CustomAnimations } from "@globals/classes/CustomAnimations";
import { CustomValidators } from "@globals/classes/CustomValidators";
import { BiCountryId } from "@globals/enums/BiLanguageAndCountryId";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { finalize } from "rxjs";

@UntilDestroy()
@Component({
    selector: "mobile-and-pin",
    templateUrl: "./mobile-and-pin.component.html",
    styleUrls: ["./mobile-and-pin.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [CustomAnimations.slideLeftRightMulti],
    standalone: false
})
export class MobileAndPinComponent implements OnInit {
  public formGroup: FormGroup;
  public showPincodeArea = false;
  public phoneWithCountryCode: string;
  public countryId: BiCountryId;
  private codeElements: ElementRef<HTMLInputElement>[];
  public error: string;

  public loading = false;

  @Input() set currentPage(value: number) {
    this.showPincodeArea = value === 2;
  }
  @Output() currentPageChange = new EventEmitter<number>();

  @Output() onLoginSuccess: EventEmitter<any> = new EventEmitter<string>();

  @ViewChild("pin1") pin1: ElementRef<HTMLInputElement>;
  @ViewChild("pin2") pin2: ElementRef<HTMLInputElement>;
  @ViewChild("pin3") pin3: ElementRef<HTMLInputElement>;
  @ViewChild("pin4") pin4: ElementRef<HTMLInputElement>;

  constructor(
    public localizationHelper: EnLocalizationHelperService,
    private enrolleeService: EnrolleeService,
    private enrollmentService: EnrollmentService,
    private cd: ChangeDetectorRef,
    private authService: EnrollmentAuthenticationService,
    private globalStateService: GlobalStateAndEventsServiceEn,
    private translator: BiTranslateService
  ) {}

  ngOnInit() {
    this.countryId = this.globalStateService.getCurrentStateValue().countryId;
    this.formGroup = new FormGroup({
      phone: new FormControl(undefined, [Validators.required, CustomValidators.digitsOnly()]),
      pin1: new FormControl(undefined, [Validators.required]),
      pin2: new FormControl(undefined, [Validators.required]),
      pin3: new FormControl(undefined, [Validators.required]),
      pin4: new FormControl(undefined, [Validators.required])
    });
  }

  public getElement(index: number): HTMLInputElement {
    return this.codeElements[index].nativeElement;
  }

  public isValid(element: HTMLInputElement) {
    return element.value.length > 0;
  }

  public handleInput(e: InputEvent, index: number) {
    const source = this.getElement(index);

    if (e.inputType === "deleteContentBackward" || e.inputType === "deleteContentForward") {
      source.value = " ";
      this.error = undefined;
      return;
    }

    if (source.value.length === 0) {
      this.error = undefined;
    }

    if (!isNaN(Number(e.data))) {
      if (source.value.length > 1) {
        source.value = source.value.toString().slice(0, 1);
      }

      if (this.isValid(this.getElement(0)) && this.isValid(this.getElement(1)) && this.isValid(this.getElement(2)) && this.isValid(this.getElement(3))) {
        this.validateCode();
      } else {
        for (let i = 0; i < 4; i++) {
          if (!this.isValid(this.getElement(i))) {
            this.getElement(i).focus();
            break;
          }
        }
      }
    } else {
      source.value = "";
    }
  }

  public handlePaste(e: Event) {
    e.preventDefault();
  }

  public handleKeyup(e: KeyboardEvent, index: number) {
    if (e.key == undefined) return;

    const previous = index > 0 ? this.codeElements[index - 1].nativeElement : undefined;
    const source = this.codeElements[index].nativeElement;

    if (isNaN(Number(e.key))) {
      e.preventDefault();
    }

    if (e.key.toLowerCase() === "delete") {
      if (source.value === "") {
        if (index > 0) {
          previous.focus();
          previous.value = "";
        }
      } else source.value = "";
    }

    if (e.key.toLowerCase() === "backspace") {
      e.preventDefault();
      if (source.value === " ") {
        source.value = "";
      } else if (index > 0) {
        previous.focus();
        previous.value = "";
      }
    }
  }

  public get phoneCtrl() {
    return this.formGroup.get("phone");
  }

  public get pin1Ctrl() {
    return this.formGroup.get("pin1");
  }

  public get pin2Ctrl() {
    return this.formGroup.get("pin2");
  }

  public get pin3Ctrl() {
    return this.formGroup.get("pin3");
  }

  public get pin4Ctrl() {
    return this.formGroup.get("pin4");
  }

  public sendCode(phoneWithCountryCode: string) {
    if (this.phoneCtrl.valid) {
      this.loading = true;
      this.phoneWithCountryCode = phoneWithCountryCode;
      this.enrolleeService
        .requestPinCodeAttempt(this.phoneWithCountryCode, this.enrollmentService.accessToken)
        .pipe(
          untilDestroyed(this),
          finalize(() => {
            this.loading = false;
            this.cd.detectChanges();
          })
        )
        .subscribe({
          next: () => {
            this.showPincodeArea = true;
            this.currentPageChange.emit(2);

            setTimeout(() => {
              this.codeElements = [this.pin1, this.pin2, this.pin3, this.pin4];
              this.pin1.nativeElement.focus();
            }, 200);

            this.error = undefined;
          },
          error: (err: HttpErrorResponse) => {
            if (err.status === 429) {
              this.error = this.translator.instant("enrollment.RequestCountExceeded");
            } else if (err.error?.errorMessage === "Invalid") {
              this.error = this.translator.instant("iFrame.Subscription.InvalidPleaseReload");
            }
          }
        });
    }
  }

  public validateCode() {
    const code = `${this.getElement(0).value}${this.getElement(1).value}${this.getElement(2).value}${this.getElement(3).value}`;

    this.enrolleeService
      .verifyPinCode(this.phoneWithCountryCode, code)
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          this.cd.detectChanges();
        })
      )
      .subscribe({
        next: token => {
          this.error = undefined;
          this.authService.saveTokenModel(token);

          this.onLoginSuccess.emit(token.enrolleeCreationDate);
        },
        error: (err: HttpErrorResponse) => {
          if (err.error.errorMessage?.indexOf("Invalid PIN") !== -1) {
            this.error = this.translator.instant("shared.InvalidPin");
          }
        }
      });
  }

  public sendNewCode() {
    this.pin1Ctrl.reset();
    this.pin2Ctrl.reset();
    this.pin3Ctrl.reset();
    this.pin4Ctrl.reset();
    this.sendCode(this.phoneWithCountryCode);
  }
}
