import { HttpErrorResponse, HttpHandler, HttpHeaderResponse, HttpInterceptor, HttpProgressEvent, HttpRequest, HttpResponse, HttpSentEvent, HttpUserEvent } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { RouteNamesEn } from "@shared-sub/classes/RouteNamesEn";
import { BehaviorSubject, catchError, Observable, throwError } from "rxjs";
import { EnrollmentAuthenticationService } from "./security/enrollment-authentication.service";

/**
 * Http interceptor used to add middleware for all Http requests. When 401's occur, automatic handling of token refresh and rerequest/retry is performed. 400's are also handled.
 * When the Http error is not something that can automatically be handled, we throw the HttpErrorResponse to our global ErrorHandler (BiErrorHandlerService).
 * With inspiration from here: https://www.intertech.com/Blog/angular-4-tutorial-handling-refresh-token-with-new-httpinterceptor/
 */
@Injectable()
export class HttpInterceptorEn implements HttpInterceptor {
  /**
   * Used so requests can wait until the token is set. "True" means set, "false" means not set
   */
  private isTokenSetSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private authService: EnrollmentAuthenticationService,
    private router: Router
  ) {
    //this.authService = injector.get(ENROLLMENT_SERVICE_TOKEN); // to prevent circular dependency between AuthenticationService and this Http interceptor, we manually use the injector here
  }

  private addAuthHeader(req: HttpRequest<any>, token: string): HttpRequest<any> {
    return req.clone({ setHeaders: { Authorization: "Bearer " + token } });
  }

  /**
   * Handles incomming requests (e.i. adds the necessary middleware logic)
   */
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    const token = this.authService.enrollmentTokenModel;

    // Now handle request. If no token, don't add it
    return next.handle(token ? this.addAuthHeader(req, token.accessToken) : req).pipe(
      catchError((error: Error) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            // if 401, it could be a login attempt - then just throw error.
            //if (req.url.includes(ApiRoutesSubscriptionApp.userRoutes.login) || req.url.includes(ApiRoutesSubscriptionApp.userRoutes.twoFactorAuthenticate)) return observableThrowError(error);

            return this.handle401Error(req, next); // could be invalid access token OR refresh token
          }
        }
        // If we reached this, just return the error
        return throwError(() => error);
      })
    );
  }

  private handle401Error(req: HttpRequest<unknown>, next: HttpHandler) {
    // send back to login
    this.router.navigate([RouteNamesEn.login]);

    return next.handle(this.addAuthHeader(req, this.authService.enrollmentTokenModel.accessToken));
  }
}
