import { NgModule, inject } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AppCanActivateGuard } from "@core-sub/routing/guards/en-app-can-activate.guard";
import { EnrollmentAuthenticationService } from "@core-sub/security/enrollment-authentication.service";
import { BiTranslateDirective, BiTranslatePipe } from "@globals/bi-translate";
import { RouteNamesEn } from "@shared-sub/classes/RouteNamesEn";
import { EnAddressSelectorComponent } from "@shared-sub/components/en-address-selector/en-address-selector.component";
import { EnPhoneInputModule } from "@shared-sub/components/en-phone-input/en-phone-input.module";
import { SharedModule } from "@shared-sub/shared.module";
import { ButtonModule } from "primeng/button";
import { CheckboxModule } from "primeng/checkbox";
import { DialogModule } from "primeng/dialog";
import { DialogService } from "primeng/dynamicdialog";
import { InputTextareaModule } from "primeng/inputtextarea";
import { StepsModule } from "primeng/steps";
import { MobileAndPinModule } from "../../features-shared/mobile-and-pin/mobile-and-pin.module";
import { SendATipDialogContentModule } from "../../features-shared/send-a-tip-dialog-content/send-a-tip-dialog-content.module";
import { EnTermsAndConditionsModule } from "../../features-shared/terms-and-conditions/en-terms-and-conditions.module";
import { EnrollmentFinishedMessageComponent } from "./enrollment-finished-message/enrollment-finished-message.component";
import { EnrollmentStepsMainComponent } from "./enrollment-steps-main.component";
import { EnterAddressComponent } from "./enter-address/enter-address.component";
import { MobileAndPinStepComponent } from "./mobile-and-pin-step/mobile-and-pin-step.component";
import { SenderSelectionComponent } from "./sender-selection/sender-selection.component";

const enrollmentStepsRouteGuardFn = () => !inject(EnrollmentAuthenticationService).enrollmentTokenModel?.enrolleeCreationDate;

const routes: Routes = [
  {
    path: RouteNamesEn.enrollmentStepsRoutes.main,
    component: EnrollmentStepsMainComponent,
    data: { state: "enrollment" },
    canActivate: [enrollmentStepsRouteGuardFn],
    children: [
      {
        path: "",
        pathMatch: "full",
        redirectTo: RouteNamesEn.enrollmentStepsRoutes.step1,
        data: { state: "enrollment" }
      },
      {
        path: RouteNamesEn.enrollmentStepsRoutes.step1,
        component: MobileAndPinStepComponent,
        data: { state: "enrollment" }
      },
      {
        path: RouteNamesEn.enrollmentStepsRoutes.step2,
        component: EnterAddressComponent,
        data: { state: "enrollment" },
        canActivate: [AppCanActivateGuard]
      },
      {
        path: RouteNamesEn.enrollmentStepsRoutes.step3,
        component: SenderSelectionComponent,
        data: { state: "enrollment" },
        canActivate: [AppCanActivateGuard]
      }
    ]
  }
];

const primeNgModules = [StepsModule, DialogModule, InputTextareaModule, CheckboxModule];

@NgModule({
  declarations: [EnrollmentStepsMainComponent, MobileAndPinStepComponent, EnterAddressComponent, SenderSelectionComponent, EnrollmentFinishedMessageComponent],
  imports: [
    SharedModule,
    EnPhoneInputModule,
    MobileAndPinModule,
    SendATipDialogContentModule,
    EnAddressSelectorComponent,
    EnTermsAndConditionsModule,
    RouterModule.forChild(routes),
    ...primeNgModules,
    ButtonModule,
    BiTranslatePipe,
    BiTranslateDirective
  ],
  providers: [DialogService]
})
export class EnrollmentStepsModule {}
