import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppModule } from "./app/subscription-app.module";
import { environment } from "./environments/environment";

if (environment.production) {
  let lang = sessionStorage.getItem("language");
  if (!lang) lang = getLanguageCodeByHostName();
  if (!lang) lang = "da";

  // Insert Cookie Information script
  if (lang === "se") lang = "sv"; // Cookie Information uses sv and not se
  const scriptCookie = window.document.createElement("script");
  scriptCookie.src = "https://policy.app.cookieinformation.com/uc.js";
  scriptCookie.id = "CookieConsent";
  scriptCookie.setAttribute("data-culture", lang.toUpperCase());
  document.head.appendChild(scriptCookie);

  if (environment.production) {
    enableProdMode();
  }
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));

/**
 * Returns language code by looking at hostname. If it cannot be determined, null is returned.
 * NOTE: the Enrollment WEB app does NOT use language code as prefix like the SMS-Service WEB app, so we only check the postfix.
 */
export function getLanguageCodeByHostName() {
  if (location.hostname.indexOf("app.") !== -1) return "se";
  if (location.hostname.indexOf(".dk") !== -1) return "da";
  if (location.hostname.indexOf(".fi") !== -1) return "fi";
  if (location.hostname.indexOf(".no") !== -1) return "no";

  return null;
}
