import { Component } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";

export interface EnrollmentFinishedMessageEvents {
  onLogout: () => void;
  onProceed: () => void;
}

@Component({
    selector: "enrollment-finished-message",
    templateUrl: "./enrollment-finished-message.component.html",
    styles: [":host{display: block; padding: 0 .5rem 1rem}"],
    standalone: false
})
export class EnrollmentFinishedMessageComponent {
  constructor(private dialogConfig: DynamicDialogConfig, private dialogRef: DynamicDialogRef) {
    if (!this.dialogConfig.data) throw new Error("EnrollmentFinishedMessageComponent: please provide dialog data object with event handlers!");
  }

  public onLogOutClicked() {
    if ((this.dialogConfig.data as EnrollmentFinishedMessageEvents).onLogout) {
      (this.dialogConfig.data as EnrollmentFinishedMessageEvents).onLogout();
      this.dialogRef.close();
    }
  }

  public proceedToMySenders() {
    if ((this.dialogConfig.data as EnrollmentFinishedMessageEvents).onProceed) {
      (this.dialogConfig.data as EnrollmentFinishedMessageEvents).onProceed();
      this.dialogRef.close();
    }
  }
}
