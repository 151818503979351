import { ChangeDetectionStrategy, Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from "@angular/core";
import { SenderExt } from "@models-sub/ext/SenderExt";
import { FadeAnimations } from "@shared-sub/animations/fade-animations";

export interface SenderSelectionChangedEvent {
  sender: SenderExt;
  selected: boolean;
}

@Component({
    selector: "en-sender",
    templateUrl: "./en-sender.component.html",
    styleUrls: ["./en-sender.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [FadeAnimations.fadeIn],
    standalone: false
})
export class EnSenderComponent {
  @Input() sender: SenderExt;
  @Input() expanded: boolean;
  @Input() selectable = true;

  @Input() selected: boolean;
  @Output() selectedChange = new EventEmitter<boolean>();
  @Output() onSelectionChange = new EventEmitter<boolean>();

  public get enrolled(): boolean {
    return this._enrolled;
  }
  @Input() set enrolled(value: boolean) {
    this._enrolled = value;
    this.accordionClasses = value ? "en-enrolled" : "en-unenrolled";
  }

  @ContentChild("footer") footer: TemplateRef<any>;

  public accordionClasses = "en-enrolled";

  private _enrolled = true;

  public clickSender(checked: boolean): void {
    this.selectedChange.emit(checked);
    this.onSelectionChange.emit(checked);
  }
}
