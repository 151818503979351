import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "highlightSpaces",
    standalone: false
})
export class BiHighlightSpacesPipe implements PipeTransform {
  transform(value: string): string {
    const regex = /^(\s*)(.*?)(\s*)$/;
    const matches = regex.exec(value);
    const leadingSpaces = matches[1];
    const trailingSpaces = matches[3];
    let highlightedValue = value.trim();

    if (leadingSpaces) {
      highlightedValue = leadingSpaces + '<span class="highlight-spaces">&nbsp;</span>' + highlightedValue;
    }

    if (trailingSpaces) {
      highlightedValue = highlightedValue + '<span class="highlight-spaces">&nbsp;</span>' + trailingSpaces;
    }

    return highlightedValue;
  }
}
