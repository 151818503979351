import { Pipe, PipeTransform } from "@angular/core";

/**
 * Pipe that transforms a yearMonth value (202301) to month year format (01/2023).
 */
@Pipe({
    name: "yearMonth",
    standalone: false
})
export class BiYearMonthPipe implements PipeTransform {
  transform(value: number): string {
    if (value == null) {
      return null;
    }
    const valueString = value.toString();
    const year = valueString.slice(0, 4);
    const month = valueString.slice(4);
    return `${month}/${year}`;
  }
}
